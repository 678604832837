import React, { useContext, useRef, useState } from 'react'
import { QueryConfirmNewAddress, QueryCustomerAddress } from "../../apollo/queries";
import { SelectCustomerAddress, UpdateAddressDetails } from "../../apollo/mutations";
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { LocationContext } from '../../context/Location';
import { useTranslation } from 'react-i18next';
import { useMutation, gql, useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import UseCookie from '../../utils/cookies/useCookie';
import { IoClose } from "react-icons/io5";
import {AiOutlineSearch } from 'react-icons/ai'
import { getLocationPermission } from '../../utils/LocationServices/LocationServices';
import { toast } from 'react-toastify';
import { IoMdAdd } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete';
import GoogleMapsLocation from './GoogleMapsLocation';
import { FaArrowLeftLong } from "react-icons/fa6";
import axios from 'axios';
import { FaLocationCrosshairs } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import AdditionalAddressDetails from './AdditionalAddressDetails';


const SELECTCUSTOMERADDRESS = gql`
  ${SelectCustomerAddress}
`;

const UPDATEADDRESSDETAILS = gql`
  ${UpdateAddressDetails}
`;

const CONFIRMNEWADDRESS = gql`
  ${QueryConfirmNewAddress}
`;

const getCustomerAddresses = gql`
  ${QueryCustomerAddress}
`;

export default function SelectDeliveryAddress(props) {
  const { location, setLocation } = useContext(LocationContext)
  var [newCoordinates,setNewCoordinates] = useState([])
  var [addressInfo,SetAddressInfo] = useState(false);
  var [loginInfo,SetLoginInfo] = useState();
  var [selectedItem,setSelectedItem] = useState();
  var [selectedCoordinates,setSelectedCoordinates] = useState();
  var [confirmEditLocation,setConfirmEditLocation] = useState(false);
  var [moreAddressDetails,setMoreAddressDetails] = useState(false)
  var [loading,setLoading] = useState(false);
    const modalRef = useRef(null);
    var [loginInfo,setLoginInfo] = useState()
    const { t } = useTranslation()
    const client = useApolloClient();
    const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];     
    const [ChangeCustomerAddress] = useMutation(SELECTCUSTOMERADDRESS, { onCompleted, onError });
    const [UpdateAddressDetailsById] = useMutation(UPDATEADDRESSDETAILS);



    async function onCompleted(data) {
        //console.log("🚀 ~ ChangeCustomerAddress onCompleted ~ login:", 44)
      }
    
      function onError(errors) {
        //console.log("🚀 ~ onError ~ errors:", errors)
      }


      const [refetch, { data:confirmVerifyData, landingPageLoading:confirmVerifyLoading, error }] = useLazyQuery(CONFIRMNEWADDRESS, {
        fetchPolicy: 'network-only',
        onCompleted: (confirmVerifyData) => {
          if (confirmVerifyData) {
            if(newCoordinates?.length>0){
              //console.log("🚀 ~ React.useEffect ~ confirmVerifyData?.queryConfirmNewAddress && !confirmVerifyLoading:", confirmVerifyData?.queryConfirmNewAddress , confirmVerifyLoading)
              if(confirmVerifyData?.queryConfirmNewAddress == false && !confirmVerifyLoading){
                toast.error('Selection seem to be in a different location. Please change the address on the home page')
              }
              else if(confirmVerifyData?.queryConfirmNewAddress && !confirmVerifyLoading){
                selectNewLocation(selectedItem)
              }
              if(error){
                toast.error("error in querying data...")
              }
            }
           }
        },
      });

    // React.useEffect(() => {
    //   if (!loadingCustomerAddresses && customerAddresses) {
    //         setCustomCookie('addressInfo', JSON.stringify(customerAddresses?.queryCustomerAddress))
    //         addressInfo = customerAddresses?.queryCustomerAddress
    //         SetAddressInfo(addressInfo)
    //   }
    // }, [customerAddresses, loadingCustomerAddresses]);

    React.useEffect(()=>{
      if(props.selectAddress){
        openModalDrawer()
        verifyLoginInfo()
        getAllAvailableAddress()
      }

    },[props.selectAddress])

    const getAllAvailableAddress = async()=>{
      loading = true
      setLoading(loading)
      try{
        const { data } = await client.query({
          query: getCustomerAddresses,
          variables:{},
          fetchPolicy: 'network-only',
        });
  
        if (data) { 
          setCustomCookie('addressInfo', JSON.stringify(data?.queryCustomerAddress))
          addressInfo = data?.queryCustomerAddress
          SetAddressInfo(addressInfo)
        }
      }
      catch(error){}
      confirmEditLocation = false
      setConfirmEditLocation(confirmEditLocation)
      loading = false
      setLoading(loading)
    }

    const verifyLoginInfo = async()=>{
      loginInfo = await getCustomCookie('loginInfo')
      SetLoginInfo(loginInfo)
      // addressInfo = await getCustomCookie('addressInfo')
      // SetAddressInfo(addressInfo)
    }

    // React.useEffect(() => {
    //   //console.log("🚀 72 ~ React.useEffect ~ newCoordinates:", newCoordinates)
    //   if(newCoordinates?.length>0){
    //     //console.log("🚀 ~ React.useEffect ~ confirmVerifyData?.queryConfirmNewAddress && !confirmVerifyLoading:", confirmVerifyData?.queryConfirmNewAddress , confirmVerifyLoading)
    //     if(confirmVerifyData?.queryConfirmNewAddress == false && !confirmVerifyLoading){
    //       toast.error('Selection seem to be in a different location. Please change the address on the home page')
    //     }
    //     else if(confirmVerifyData?.queryConfirmNewAddress && !confirmVerifyLoading){
    //       selectNewLocation(selectedItem)
    //     }
    //     if(error){
    //       toast.error("error in querying data...")
    //     }
    //   }
    // }, [confirmVerifyData, confirmVerifyLoading,error]);
    

  

    const handleClickOutsideDrawer = (event) => {if (modalRef.current && !modalRef.current.contains(event.target)) {closeModalDrawer();}};

    const openModalDrawer = () => {
        document.addEventListener('mousedown', handleClickOutsideDrawer);
    };

    const closeModalDrawer = () => {
      props?.selectAnotherLocation(false)
      document.removeEventListener('mousedown', handleClickOutsideDrawer);
    };

    const handleSearch = (e) => {
      if(e?.target?.value?.trim()){
      }
    }

    const selectNewLocation = async(item) => {
      //console.log("🚀 ~ selectNewLocation ~ item:", item)
      let requestBody = {
          "addressId": item?._id
      }
        try{
          let response = await ChangeCustomerAddress({ variables:requestBody})
          //console.log("🚀 ~ changeLocation ~ response:", response)
          if(response?.data){
            let locationDetails = {
              _id:item?._id,
              "deliveryAddress": item?.deliveryAddress,
              "details": item?.details,
              "label": item?.label,
              "latitude": item?.location.coordinates[1],
              "longitude": item?.location.coordinates[0],
            }
            //console.log("🚀 ~ selectNewLocation ~ locationDetails:", locationDetails)
              setLocation(locationDetails)
              await setCustomCookie('addressInfo', JSON.stringify(response?.data?.selectCustomerAddress?.addresses))
              // props?.reloadLandingPageData?props?.reloadLandingPageData():props?.fetchLocationToDeliver()
              closeModalDrawer()
          }
       }catch(e){}
    }

    const PointToNewLocation = async()=>{
      let status = await getLocationPermission()
      if(status){return}
      props?.selectAnotherLocation(false)
      //console.log("🚀 ~ AddNewAddress ~ location:", props.currentLocation)
      let params = {...props.currentLocation,latitude:parseFloat(props.currentLocation?.latitude),longitude:parseFloat(props.currentLocation?.longitude),addNewAddress:props.fetchLocationToDeliver?true:false}
      //console.log("🚀 201  ~ AddNewAddress ~ params:", params)
    //   props?.navigation?.navigate('SelectLocation',params)
    }

    const changeLocation = async(item)=>{
      if(props?.requestComponent != 'NavBar'){
          try{
              newCoordinates = item?.location?.coordinates
              setNewCoordinates(newCoordinates)
              //console.log("🚀 ~ changeLocation ~ newCoordinates:", newCoordinates)
              selectedItem = item
              setSelectedItem(selectedItem)
              await refetch({
                variables:{
                  "confirmNewAddressInput": {
                    "currentLatitude": ""+props.currentLocation?.latitude,
                    "currentLongitude": ""+props.currentLocation?.longitude,
                    "newLatitude": ""+newCoordinates?newCoordinates[1]:"",
                    "newLongitude": ""+newCoordinates?newCoordinates[0]:""
                  }
                }
              })
          }catch(err){
            //console.log("🚀 ~ changeLocation ~ err:", err)
            
          }
          // if(!confirmVerifyData?.queryConfirmNewAddress){
          //     Alert.alert(
          //       '',
          //       'Selection seem to be in a different location. Please change the address on the home page',
          //       [
          //         {
          //           text: 'Try Another',
          //           onPress: async () => {}
          //         }
          //       ],
          //       { cancelable: false }
          //     )
          //   }
          // else{
          //     selectNewLocation(item)
          // }
      }
      else{
          selectNewLocation(item)
      }
    }

    const onRegionChangeComplete = (data) => {
      console.log("🚀 ~ onRegionChangeComplete ~ data:", data)
      let apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${data.lat}&lon=${data.lng}`
      
      axios.get(apiUrl)
      .then(response => {
        selectedCoordinates={...selectedCoordinates,...data,...response?.data,lat:parseFloat(data?.lat),lng:parseFloat(data?.lng),zoom:17,description:response?.data?.display_name,deliveryAddress:response?.data?.display_name}
        console.log("🚀 ~ onRegionChangeComplete ~ selectedCoordinates:", response?.data)
        //console.log("🚀 ~ handlePanDrag ~ selectedCoordinates:", selectedCoordinates)
        setSelectedCoordinates(selectedCoordinates)
      });

    }

    const handlePanDrag = (data) => {
      //console.log("🚀 ~ onPanDrag ~ data:", data)
      
    }

    const OpenAddressDetailsModal = (value,loginDetails) => {
      console.log("🚀 ~ OpenAddressDetailsModal ~ loginDetails:", loginDetails)
      if(loginDetails){props?.setLoginDetails(loginDetails)}
      moreAddressDetails = value
      setMoreAddressDetails(moreAddressDetails)
    }

    const confirmLocation = ()=>{
      if(selectedCoordinates?._id){
        OpenAddressDetailsModal(true)
      }
      else{
        let locationDetails = {
          "deliveryAddress": selectedCoordinates?.description,
          "latitude": selectedCoordinates?.lat,
          "longitude": selectedCoordinates?.lng,
        }
        //console.log("🚀 ~ confirmLocation ~ selectedCoordinates:", selectedCoordinates)
        console.log("🚀 ~ selectNewLocation ~ locationDetails:", locationDetails)
        setLocation(locationDetails)
        closeModalDrawer()
      }
    }

    const handlePlaceSelected = (placeDetails) => {
      //console.log("🚀 ~ handlePlaceSelected ~ place:", placeDetails?.description)
      let locationDetails = {
        "deliveryAddress": placeDetails?.description,
        "latitude": ""+placeDetails?.coordinates?.lat,
        "longitude": ""+placeDetails?.coordinates?.lng,
      }
      selectedCoordinates={...placeDetails?.coordinates,zoom:17,description:placeDetails?.description,...placeDetails}
      console.log("🚀 ~ handlePlaceSelected ~ selectedCoordinates:", selectedCoordinates)
      setSelectedCoordinates(selectedCoordinates)
      //console.log("🚀 ~ selectNewLocation ~ locationDetails:", locationDetails)
      // setLocation(locationDetails)
      // closeModalDrawer()
    };

    const openEditAddress = (addressDetails) => {
      let locationDetails = {
        ...addressDetails,
        description:addressDetails?.deliveryAddress,
        coordinates:{
          lat:parseFloat(addressDetails?.location?.coordinates[1]),
          lng:parseFloat(addressDetails?.location?.coordinates[0]),
        }
      }
      handlePlaceSelected(locationDetails)
    }

    const confirmEditAddress = async(label,aditionalInfo,addressInfo) => {
      let requestBody = {
        "addressDetails": {
          "deliveryAddress": addressInfo?.deliveryAddress,
          "details": aditionalInfo?aditionalInfo:"",
          "label": label?label:"",
          "longitude": ""+addressInfo?.lng,
          "latitude": ""+addressInfo?.lat,
        },
        "addressId": addressInfo?._id,
      }
      try{
        let response = await UpdateAddressDetailsById({ variables:requestBody})
        if(addressInfo?.selected){
          setLocation({...requestBody?.addressDetails,_id:addressInfo?._id})
          setCustomCookie('addressInfo', JSON.stringify(response?.data?.updateAddressDetails))
        }
        toast.success(`Address Updated Successfully`)
        closeModalDrawer()      
      }catch(e){
        toast.error('Failed to edit address, try again!');
      }
    }


  return (
    <>
      {props.selectAddress && <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="false">
          <div className="fixed inset-0 bg-modalBackGround transition-opacity" onClick={closeModalDrawer}></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
                  <div ref={modalRef} style={{backgroundColor:Colors.colorTheme[colors?.buttonText]}} className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 h-full  w-full sm:w-full sm:max-w-xl`}>
                      <div className='shadow relative flex flex-row items-center justify-center'>
                          <h2 className={`mt-3p mb-1p font-semibold text-md sm:text-md md:text-lg lg:text-lg xl:text-xl text-${colors?.heading}`}>{selectedCoordinates?._id?t('EditAddress'):t('ChooseYourLocation')}</h2>
                          <IoClose onClick={closeModalDrawer} className={`absolute right-3 top-3 sm:right-3 sm:top-3 md:right-4 md:top-4 lg:right-5 lg:top-5 h-4 sm:h-4 md:h-4 lg:h-6 w-4 sm:w-4 md:w-4 lg:w-6  text-black-400 cursor-pointer text-${colors?.heading}`} aria-hidden="true" />
                      </div>
                      <hr></hr>
                      {selectedCoordinates?
                      (moreAddressDetails ?
                        <div className='mx-4p my-2p'>
                          <AdditionalAddressDetails editAddressDetails={true} location={selectedCoordinates} addNewAddress={confirmEditAddress} colors={colors} moreAddressDetails={moreAddressDetails} OpenAddressDetailsModal={OpenAddressDetailsModal} />
                        </div>
                        :<div className='flex flex-col justify-center mx-4p my-2p relative h-96 sm:h-96 md:h-70vh lg:h-80vh xl:h-80vh xxl:h-80vh w-xl overflow-y-scroll hide-scroll-bar' >
                        <div className={`flex align-left items-left justify-left text-md sm:text-md md:text-md lg:text-xl xl:text-xl items-center gap-2 text-${colors?.heading} font-bold`}>
                          <FaArrowLeftLong className={`cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 text-${colors?.heading}`} onClick={()=>{selectedCoordinates=false;setSelectedCoordinates(selectedCoordinates)}} />
                          <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{selectedCoordinates?._id?t('EditLocation'):"Confirm Location"}</div>
                        </div>
                        <GoogleMapsLocation coordinates={selectedCoordinates} onRegionChangeComplete={onRegionChangeComplete} onPanDrag={handlePanDrag} />
                        <div className='mt-2 p-2p flex flex-col justify-center items-center  rounded' style={{ backgroundColor: Colors.colorTheme[colors?.backgroundColor] }}>
                          <span className={`my-1 text-md sm:text-md md:text-md lg:text-md xl:text-md text-${colors?.heading}`}>{selectedCoordinates?.description}</span>
                          <button className="w-full bg-appThemeColorVeryDark text-white items-center rounded p-2p" onClick={confirmLocation}>
                            <div className='flex flex-wrap items-center justify-center w-full' >
                              <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mx-1">Confirm Location & Continue</span>
                            </div>
                          </button>
                        </div>
                      </div>)
                      :<div className='flex flex-col justify-center mx-4p my-2p'>
                        <GooglePlacesAutocomplete onPlaceSelected={handlePlaceSelected} />
                        <div>
                          <div className='mt-2 cursor-pointer flex flex-row gap-2 items-center rounded hover:text-appThemeColor' onClick={()=>{props?.handleGetLocation();closeModalDrawer()}}>
                            <FaLocationCrosshairs className={`cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 text-appThemeColorVeryDark font-bold`} />
                            <span className={`my-1 text-md sm:text-md md:text-md lg:text-md xl:text-md text-appThemeColorVeryDark font-bold`}>{t('getCurrentLocation')}</span>
                          </div>
                          {<>
                              <div className={`pl-1 text-${colors?.heading} font-bold text-[12px] my-[5px] `} style={{ color: colors?.textlightGrey }}>
                              {/* <div className={`text-${colors?.heading} font-bold text-[12px] my-[5px] `} style={{ color: colors?.textlightGrey }}> */}
                                {t('savedAddress')}
                              </div>
                              {addressInfo && addressInfo.length > 0 ? (
                                <div className="h-96 overflow-y-scroll hide-scroll-bar" showsVerticalScrollIndicator={false}>
                                  {addressInfo.map((item, index) => (
                                    <div className='relative'>
                                      <button
                                        key={`addressInfo_${index}`}
                                        className={`w-full mx-0 my-[5px] py-[5px] rounded hover:border-appThemeColorDark ${item?.selected ?'border-appThemeColorDark':'border-appThemeColorAboveLight'} border-appThemeColorAboveLight border-2  shadow transition duration-200 transform active:scale-90`}
                                        onClick={() => changeLocation(item)}
                                        disabled={!item?.isActive}
                                        style={{ backgroundColor: item?.selected ?Colors.colorTheme[colors?.categorySelected] : Colors.colorTheme[colors?.backgroundColor],}}
                                      >
                                        <div className="flex flex-row items-center gap-2 p-2p">
                                          <FaLocationDot className={`cursor-pointer text-green-600 text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl  text-${colors?.heading}`} />
                                          <div className="flex flex-col items-left">
                                            <text className={`text-left text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading} font-bold`}>{(item?.label ? item?.label : '')}</text>
                                            <text className={`text-left text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>
                                              {(item?.details ? item?.details + ', ' : '') + item?.deliveryAddress}
                                            </text>
                                          </div>
                                        </div>
                                      </button>
                                        <MdEdit onClick={(e) =>{e.stopPropagation();openEditAddress(item)}}  className="absolute cursor-pointer top-3 right-1 text-md sm:text-md md:text-lg  lg:text-xl  xl:text-xl text-appThemeColorVeryDark transition duration-200 transform active:scale-50" />
                                    </div>

                                  ))}
                                </div>
                              ) : (
                                <div className="flex justify-center items-center h-[400px]">
                                  {loading?
                                      <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>
                                      :<div className="text-center">{t(`Nosavedaddresses`)}</div>}
                                </div>
                              )}
                          </>}
                        </div>
                      </div>}
                  </div>
              </div>
          </div>
      </div>}
    </>
  )
}
